// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blacklist-js": () => import("./../../../src/templates/blacklist.js" /* webpackChunkName: "component---src-templates-blacklist-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-disclaimer-js": () => import("./../../../src/templates/disclaimer.js" /* webpackChunkName: "component---src-templates-disclaimer-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-post-amp-js": () => import("./../../../src/templates/post.amp.js" /* webpackChunkName: "component---src-templates-post-amp-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-resort-amp-js": () => import("./../../../src/templates/resort.amp.js" /* webpackChunkName: "component---src-templates-resort-amp-js" */),
  "component---src-templates-resort-js": () => import("./../../../src/templates/resort.js" /* webpackChunkName: "component---src-templates-resort-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-templates-testimonials-js": () => import("./../../../src/templates/testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-js" */),
  "component---src-templates-thankyou-js": () => import("./../../../src/templates/thankyou.js" /* webpackChunkName: "component---src-templates-thankyou-js" */),
  "component---src-templates-tips-js": () => import("./../../../src/templates/tips.js" /* webpackChunkName: "component---src-templates-tips-js" */)
}

