const minute = 60000;
const timers = {
  '30s': minute / 2,
  '1m': minute,
  '2m': 2 * minute,
  '3m': 3 * minute,
  '4m': 4 * minute,
  //'5m': 5 * minute,
  //'6m': 6 * minute,
  //'7m': 7 * minute,
};
// eslint-disable-next-line
const ev = (event_category, time) => {
  setTimeout(() => {
    window.gtag('event', 'tiempo', { event_category });
  }, time);
};
exports.onRouteUpdate = function ({ location }) {
  if (process.env.NODE_ENV !== 'production' || typeof window.gtag !== 'function') {
    return null;
  }
  // TODO: agregar páginas que se excluirán
  /*const pathIsExcluded = location
    && typeof window.excludeGAPaths !== `undefined`
    && window.excludeGAPaths.some(rx => rx.test(location.pathname));

  if (pathIsExcluded) return null;*/

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    if (window.gtag !== undefined) {
      const pagePath = location ? location.pathname + location.search + location.hash : undefined;
      window.gtag('event', 'page_view', { page_path: pagePath });
      Object.keys(timers).forEach((key) => ev(key, timers[key]));
    }
    if (window.fbq !== undefined) {
      window.fbq('track', 'PageView');
    }
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
};
